<template>
    <div id="ff-cash">
        <div class="card card-custom">
            <div class="card-body p-0">
                <div class="1py-8 px-8 py-lg-20 px-lg-10">
                    <h4 style="text-align: end">Opening date: {{ new Date(dateBox) }}</h4>
                    <h5 style="text-align: end">Clinic: {{ facilityName }}</h5>
                    <hr />
                    <b-container>
                        <b-row>
                            <b-col>
                                <b-table :fields="fields" :items="[1]">
                                    <template #cell(total_patients)>
                                        {{ totalPatients }}
                                    </template>
                                    <template #cell(total_cash)>
                                        $ {{ current_balance }}
                                    </template>
                                    <template #cell(total_credit_cash)>
                                        $ {{ total_credit_cash }}
                                    </template>
                                    <template #cell(total_income)> $ {{ income }} </template>
                                </b-table>
                                <hr />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-table :fields="fieldsData" :items="[1]">
                                    <template #cell(frontdesks)>
                                        <ul>
                                            <li v-for="cash in cashes">
                                                {{ cash.frontdesks.name }}
                                                {{ cash.frontdesks.lastname }}
                                            </li>
                                        </ul>
                                    </template>
                                    <template #cell(references)>
                                        <ul>
                                            <li v-for="redes in optionsR.labels">
                                                {{ redes }}
                                            </li>
                                            <li>Total References: {{ totalPatients }}</li>
                                        </ul>
                                    </template>
                                    <template #cell(type_visits)>
                                        <ul>
                                            <li v-for="typeVisit in optionsTV.labels">
                                                {{ typeVisit }}
                                            </li>
                                            <li>
                                                Total visits:
                                                {{ totalPatients - seriesTV[6] - seriesTV[7] }}
                                            </li>
                                        </ul>
                                    </template>
                                </b-table>
                                <hr />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div v-for="cash in cashes">
                                    <b-row>
                                        <b-col md="1">
                                            <b-avatar size="48" :src="cash.frontdesks.avatar" />
                                        </b-col>
                                        <b-col md="5" align-self="center">
                                            <h1>
                                                {{ cash.frontdesks.name }}
                                                {{ cash.frontdesks.lastname }} - ${{ cash.income }}
                                            </h1>
                                        </b-col>
                                    </b-row>
                                    <hr />
                                    <b-table small hover :fields="fieldsTable" :items="cash.movements" responsive show-empty
                                        empty-text="No matching records found">
                                        <template #cell(patient)="data">
                                            <div class="list-unstyled my-2 facility"
                                                style="word-wrap: break-word; overflow: hidden" v-if="data.item.appointment != null &&
                                                    data.item.appointment.patients
                                                    ">
                                                <p>
                                                    <span style="word-wrap: break-word; overflow: hidden">
                                                        {{ data.item.appointment.patients.name }}
                                                        {{ data.item.appointment.patients.lastname }}
                                                    </span>
                                                </p>
                                            </div>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(diagnostic_imagings)="data">
                                            <div class="list-unstyled my-2 facility"
                                                style="word-wrap: break-word; overflow: hidden" v-if="data.item.appointment != null &&
                                                    data.item.appointment.office_visit.diagnostic_imagings
                                                        .length
                                                    ">
                                                <p v-for="(item, i) in data.item.appointment.office_visit
                                                        .diagnostic_imagings" :key="i">
                                                    <span style="word-wrap: break-word; overflow: hidden">{{ item.name
                                                    }}</span>
                                                </p>
                                            </div>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(package)="data">
                                            <div class="list-unstyled my-2 facility">
                                                <div variant="info" v-if="data.item.appointment != null &&
                                                    data.item.appointment.office_visit.package
                                                    ">
                                                    <span>{{
                                                        data.item.appointment.office_visit.package.name
                                                    }}</span>
                                                </div>
                                                <span v-else>N/A</span>
                                            </div>
                                        </template>
                                        <template #cell(laboratories)="data">
                                            <div class="list-unstyled my-2 facility" v-if="data.item.appointment != null &&
                                                data.item.appointment.office_visit.laboratories.length
                                                ">
                                                <p v-for="(item, i) in data.item.appointment.office_visit
                                                    .laboratories" :key="i">
                                                    <span>{{ item.name }}</span>
                                                </p>
                                            </div>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(procedures)="data">
                                            <div class="list-unstyled my-2 facility" v-if="data.item.appointment != null &&
                                                data.item.appointment.office_visit.procedures.length
                                                ">
                                                <p v-for="(item, i) in data.item.appointment.office_visit
                                                    .procedures" :key="i">
                                                    <span>{{ item.name }}</span>
                                                </p>
                                            </div>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(tests)="data">
                                            <div class="list-unstyled my-2 facility" v-if="data.item.appointment != null &&
                                                data.item.appointment.office_visit.tests.length
                                                ">
                                                <p v-for="(item, i) in data.item.appointment.office_visit
                                                    .tests" :key="i">
                                                    <span>{{ item.name }}</span>
                                                </p>
                                            </div>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(treatments)="data">
                                            <div class="list-unstyled my-2 facility" v-if="data.item.appointment != null &&
                                                data.item.appointment.office_visit.treatments.length
                                                ">
                                                <p v-for="(item, i) in data.item.appointment.office_visit
                                                    .treatments" :key="i">
                                                    <span>{{ item.name }}:
                                                        {{ item.pivot.cant ? item.pivot.cant : 1 }}</span>
                                                </p>
                                            </div>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(total)="data">
                                            <span>${{ getTotal(data.item.appointment) }}</span>
                                        </template>
                                    </b-table>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <hr />
                                <h4>Observation: {{ observation }}</h4>
                                <hr />
                                <div class="cardSign">
                                    <img :src="photoUrl" />
                                    <h5>Sign</h5>
                                </div>
                                <hr />
                                <b-button class="boton" variant="outline-primary" @click="sendData">
                                    Print
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
                <ToastNotification ref="toast" />
            </div>
        </div>
        <ToastNotification ref="toast" />
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
} from "bootstrap-vue";

import ToastNotification from "@/components/ToastNotification";
import diagnostics from "./components/diagnostics.vue";
import VueApexCharts from "vue-apexcharts";
import NotificationMixin from "@/mixins/NotificationMixin";
import axiosC from "@/core/services/api/cash-account";
import axiosEC from "@/core/services/api/external-settings/cloudinary";
import html2pdf from "html2pdf.js";

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormInvalidFeedback,
        VueApexCharts,
        diagnostics,
        ToastNotification,
    },
    mixins: [NotificationMixin],
    mounted() {
        this.dailyData = this.$store.state.closingCash;
        this.dateBox = this.dailyData?.opening;
        this.cashes = this.dailyData?.cashes;
        this.facilityName = this.dailyData?.facilities.name;
        this.photoUrl = this.dailyData?.photoUrl;
        this.observation = this.dailyData?.observation;
    },
    watch: {
        dailyData() {
            let cashes = this.dailyData?.cashes;
            let total_credit_cash = 0;
            this.current_balance = this.dailyData?.current_balance;
            this.income = this.dailyData?.income;
            cashes.map((box) => {
                box.movements.map((movement) => {
                    this.totalPatients += 1;
                    if (!movement.appointment) {
                        this.seriesTV[7] += 1;
                        this.seriesR[7] += 1;
                        return;
                    }
                    total_credit_cash += movement.appointment.cc
                        ? movement.appointment.cc
                        : 0;
                    if (movement.appointment.patients.reference) {
                        switch (movement.appointment.patients.reference.name) {
                            case "Radio":
                                this.seriesR[0] += 1;
                                break;
                            case "TV":
                                this.seriesR[1] += 1;
                                break;
                            case "Facebook":
                                this.seriesR[2] += 1;
                                break;
                            case "Reference":
                                this.seriesR[3] += 1;
                                break;
                            case "Sign":
                                this.seriesR[4] += 1;
                                break;
                            case "Flyers":
                                this.seriesR[5] += 1;
                                break;
                            case "Internet":
                                this.seriesR[6] += 1;
                                break;
                            default:
                                this.seriesR[7] += 1;
                                break;
                        }
                    } else this.seriesR[7] += 1;
                    switch (movement.appointment.type_visits_id) {
                        case 1:
                            this.seriesTV[0] += 1;
                            break;
                        case 2:
                            this.seriesTV[1] += 1;
                            break;
                        case 3:
                            this.seriesTV[2] += 1;
                            break;
                        case 4:
                            this.seriesTV[3] += 1;
                            break;
                        case 5:
                            this.seriesTV[4] += 1;
                            break;
                        case 6:
                            this.seriesTV[5] += 1;
                            break;
                        case 7:
                            this.seriesTV[6] += 1;
                            break;
                        default:
                            this.seriesTV[7] += 1;
                            break;
                    }
                });
                this.optionsR.labels[0] = "Radio: " + this.seriesR[0];
                this.optionsR.labels[1] = "TV: " + this.seriesR[1];
                this.optionsR.labels[2] = "Facebook: " + this.seriesR[2];
                this.optionsR.labels[3] = "Reference: " + this.seriesR[3];
                this.optionsR.labels[4] = "Sign: " + this.seriesR[4];
                this.optionsR.labels[5] = "Flyers: " + this.seriesR[5];
                this.optionsR.labels[6] = "Internet: " + this.seriesR[6];
                this.optionsR.labels[7] = "Others: " + this.seriesR[7];
                this.optionsTV.labels[0] = "F/U: " + this.seriesTV[0];
                this.optionsTV.labels[1] = "N/C: " + this.seriesTV[1];
                this.optionsTV.labels[2] = "N/P: " + this.seriesTV[2];
                this.optionsTV.labels[3] = "P/I: " + this.seriesTV[3];
                this.optionsTV.labels[4] = "P/P: " + this.seriesTV[4];
                this.optionsTV.labels[5] = "R/S: " + this.seriesTV[5];
            });
            this.total_credit_cash = total_credit_cash;
        },
    },
    data() {
        return {
            observation: "",
            photoUrl: "",
            facilityName: "",
            cashes: [],
            dailyData: null,
            dateBox: "",
            optionsTV: {
                labels: ["F/U: 0", "N/C: 0", "N/P: 0", "P/I: 0", "P/P: 0", "R/S: 0"],
                dataLabels: {
                    enabled: true,
                },
            },
            optionsR: {
                labels: [
                    "Radio: 0",
                    "TV: 0",
                    "Facebook: 0",
                    "Reference: 0",
                    "Sign: 0",
                    "Flyers: 0",
                    "Internet: 0",
                    "Others: 0",
                ],
                dataLabels: {
                    enabled: true,
                },
            },
            fields: [
                "total_patients",
                "total_credit_cash",
                "total_cash",
                "total_income",
            ],
            fieldsData: ["frontdesks", "references", "type_visits"],
            fieldsTable: [
                { key: "patient", label: "Patient" },
                { key: "package", label: "Package" },
                { key: "diagnostic_imagings", label: "Diagnostic imagings" },
                { key: "laboratories", label: "Laboratories" },
                { key: "procedures", label: "Procedures" },
                { key: "tests", label: "Tests" },
                { key: "treatments", label: "Treatments" },
                { key: "total", label: "Total" },
            ],
            totalPatients: 0,
            current_balance: 0,
            income: 0,
            total_credit_cash: 0,
            seriesTV: [0, 0, 0, 0, 0, 0, 0, 0],
            seriesR: [0, 0, 0, 0, 0, 0, 0, 0],
        };
    },
    methods: {
        getTotal(appointment) {
            if (appointment) return ((appointment.cash != null ? appointment.cash : 0) + (appointment.cc != null ? appointment.cc : 0))
            return 0
        },
        desdePadre() {
            return this.descargarArchivoEnPdf()
        },
        async descargarArchivoEnPdf() {
            var element = document.getElementById("ff-cash");
            const clonedElement = element.cloneNode(true);
            const divsNoImprimir = clonedElement.getElementsByClassName("boton");
            while (divsNoImprimir.length > 0) {
                divsNoImprimir[0].parentNode.removeChild(divsNoImprimir[0]);
            }
            html2pdf().from(clonedElement).save();
            const options = {
                output: "blob",
            };
            let url = ''
            return html2pdf()
                .set(options)
                .from(clonedElement)
                .toPdf()
                .output("blob")
                .then(async (pdfBlob) => {
                    const formData = new FormData();
                    formData.append("file", pdfBlob, "nombre_del_archivo.pdf");
                    const { secure_url } = await axiosEC.createImage(formData);
                    await this.$emit('attach', `${this.dailyData.urlFile}, ${secure_url}`)
                    return `${this.dailyData.urlFile}, ${secure_url}`
                });
        },
        async sendData() {
            //   const data = {
            //     attach: `${this.dailyData.urlFile}, ${url}`,
            //     cash_id: this.dailyData.id,
            //   };
            //   axiosC.attachFile(data).then(() => {
            new Promise((resolve, reject) => {
                resolve(true);
                window.print();
            }).then(() => this.$router.push({ name: "dashboard" }));
            //   });
        },
        async uploadPhoto(file) {
            const formData = new FormData();
            formData.append("file", file);
            const { secure_url } = await axiosEC.createImage(formData);
            return secure_url;
        },
    },
};
</script>
<style lang="scss">
.cardSign {
    border-radius: 15px;
    border: 1px solid #e5e5e5;
    margin: 1%;
    padding: 1%;
}

@media print {
    .boton {
        display: none;
    }
}
</style>

<template>
    <div style="width:100%">
        <b-row>
            <b-col>
                <h5 class="mb-0">Diagnostics</h5>
                <small class="text-muted">
                    See all your diagnostics data.
                </small>
            </b-col>
        </b-row>
        <hr />
        <div v-for="(box, key) in items">
            <b-row>
                <b-col md="1">
                    <b-avatar size="48" :src="box.frontdesks.avatar" />
                </b-col>
                <b-col md="5" align-self="center">
                    <h1>{{ box.frontdesks.name }} {{ box.frontdesks.lastname }} - ${{ box.income }}</h1>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col style="word-wrap: break-word;overflow: hidden;">
                    <b-table small hover :fields="fields" :items="box.movements" responsive show-empty
                        empty-text="No matching records found" style="width: 65%">
                        <template #cell(show_details)="row">
                            <b-form-checkbox v-model="row.detailsShowing" class="vs-checkbox-con"
                                @change="row.toggleDetails">
                            </b-form-checkbox>
                        </template>
                        <template #row-details="row">
                            <b-card>
                                <b-row>
                                    <b-col>
                                        <!-- paciente -->
                                        <span v-if="row.item.appointment">
                                            Pacient: {{ row.item.appointment.patients.name }} {{
                                                row.item.appointment.patients.lastname }}
                                        </span>
                                    </b-col>
                                    <b-col>
                                        <!-- nurse -->
                                        <span v-if="row.item.appointment">
                                            Nurse: {{ row.item.appointment.office_visit.nurses.name }} {{
                                                row.item.appointment.office_visit.nurses.lastname }}
                                            <b-avatar :src="row.item.appointment.office_visit.nurses.avatar" />
                                        </span>
                                    </b-col>
                                    <b-col>
                                        <!-- oma -->
                                        <span v-if="row.item.appointment">
                                            Oma: {{ row.item.appointment.office_visit.omas.name }} {{
                                                row.item.appointment.office_visit.omas.lastname }}
                                            <b-avatar :src="row.item.appointment.office_visit.omas.avatar" />
                                        </span>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <!-- visit date -->
                                        <span v-if="row.item.appointment">
                                            Visit Date:
                                            {{ row.item.appointment.visit_date }}
                                        </span>
                                    </b-col>
                                    <b-col>
                                        <!-- Last room -->
                                        <span v-if="row.item.appointment">
                                            Last Room: {{ row.item.appointment.office_visit.room }}
                                        </span>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </template>
                        <template #cell(diagnostic_imagings)="data">
                            <div class="list-unstyled my-2 facility" style="word-wrap: break-word; overflow: hidden;"
                                v-if="data.item.appointment != null && data.item.appointment.office_visit.diagnostic_imagings.length">
                                <p v-for="(item, i) in data.item.appointment.office_visit.diagnostic_imagings" :key="i">
                                    <b-badge variant="primary"><span class="text-white"
                                            style="word-wrap: break-word;overflow: hidden;">{{ item.name }}</span></b-badge>
                                </p>
                            </div>
                            <span v-else>N/A</span>
                        </template>
                        <template #cell(package)="data">
                            <div class="list-unstyled my-2 facility">
                                <b-badge variant="primary"
                                    v-if="data.item.appointment != null && data.item.appointment.office_visit.package">
                                    <span class="text-white">{{ data.item.appointment.office_visit.package.name }}</span>
                                </b-badge>
                                <span v-else>N/A</span>
                            </div>
                        </template>
                        <template #cell(laboratories)="data">
                            <div class="list-unstyled my-2 facility"
                                v-if="data.item.appointment != null && data.item.appointment.office_visit.laboratories.length">
                                <p v-for="(item, i) in data.item.appointment.office_visit.laboratories" :key="i">
                                    <b-badge variant="primary"><span class="text-white">{{ item.name }}</span></b-badge>
                                </p>
                            </div>
                            <span v-else>N/A</span>
                        </template>
                        <template #cell(procedures)="data">
                            <div class="list-unstyled my-2 facility"
                                v-if="data.item.appointment != null && data.item.appointment.office_visit.procedures.length">
                                <p v-for="(item, i) in data.item.appointment.office_visit.procedures" :key="i">
                                    <b-badge variant="primary"><span class="text-white">{{ item.name }}</span></b-badge>
                                </p>
                            </div>
                            <span v-else>N/A</span>
                        </template>
                        <template #cell(tests)="data">
                            <div class="list-unstyled my-2 facility"
                                v-if="data.item.appointment != null && data.item.appointment.office_visit.tests.length">
                                <p v-for="(item, i) in data.item.appointment.office_visit.tests" :key="i">
                                    <b-badge variant="primary"><span class="text-white">{{ item.name }}</span></b-badge>
                                </p>
                            </div>
                            <span v-else>N/A</span>
                        </template>
                        <template #cell(treatments)="data">
                            <div class="list-unstyled my-2 facility"
                                v-if="data.item.appointment != null && data.item.appointment.office_visit.tests.length">
                                <p v-for="(item, i) in data.item.appointment.office_visit.treatments" :key="i">
                                    <b-badge variant="primary"><span class="text-white">{{ item.name }}</span></b-badge>
                                </p>
                            </div>
                            <span v-else>N/A</span>
                        </template>
                        <template #cell(movement)="data">
                            <b-badge variant="primary"><span class="text-white">{{ data.item.event.name }}</span></b-badge>
                        </template>
                        <template #cell(total)="data">
                            <span>${{ data.item.appointment ? data.item.appointment.total : data.item.value }}</span>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
            <hr />
        </div>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        BRow,
        BCol,
        BTable,
        BFormCheckbox,
    },
    props: {
        dailyData: {
            type: Object,
            default: () => { },
            required: false
        }
    },
    data() {
        return {
            items: [],
            dateBox: '',
            fields: [
                { key: "package", label: "Package" },
                { key: "diagnostic_imagings", label: "Diagnostic imagings" },
                { key: "laboratories", label: "Laboratories" },
                { key: "procedures", label: "Procedures" },
                { key: "tests", label: "Tests" },
                { key: "treatments", label: "Treatments" },
                { key: "movement", label: "Movement" },
                { key: "total", label: "Total" },
                { key: "show_details" },
            ],
        }
    },
    created() {
        this.dateBox = new Date()
    },
    watch: {
        dailyData() {
            const cashes = this.dailyData?.cashes
            const total = cashes.map(caja=>{
                caja.movements = caja.movements.filter(movimiento => movimiento.event_id != 3)
                return caja
            })
            this.items = total
        }
    },
}
</script>

<style></style>
